export const environment = {
  production: true,
  apiPerksServiceUrl: 'https://api.poshvine.com/ps/v1',
  apiBookingServiceUrl: 'https://api.poshvine.com/bs/v1',
  corePathV2: 'https://api.poshvine.com/cs/v1',
  client_id: '3e1f1a42-971e-48fd-bb24-01a8634d2b93',
  project_id: '3f1c5810-f61c-41d1-9c99-51db0a832ec4',
  country_id: '625a3e8e-e493-421a-8ece-609f10b37442',
  apiUrlPaV1: 'https://api.poshvine.com/pa/v1/',
  sentryDsn:
    'https://71b094e72aa9f4e34c756f2f971357a2@o515678.ingest.us.sentry.io/4507454616764416',
};
