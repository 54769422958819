import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/zee5-campaign/src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        'X-Client-Id': environment.client_id,
        'X-Project-Id': environment.project_id,
        'X-country-Id': environment.country_id,
      },
    });
    let currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      if (currentUser.token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + `${currentUser.token}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<unknown>) => {
          // TODO: remove later
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          if (error['status'] == 401) {
            // TODO: remove later
            console.log(error);
          } else if (
            error['status'] != 200 &&
            error['status'] != 401 &&
            error['status'] != 404
          ) {
            // TODO: remove later
            console.log(error['status']);
          }
        }
      )
    );
  }
}
