/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: Fix usage of `any` for this whole file

import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isUserLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tokenExpired: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(private router: Router) {
    if (
      localStorage.getItem('currentUser') &&
      !window.location.pathname.includes('login')
    ) {
      const currentUser = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      if (currentUser && currentUser.token) {
        const token = JSON.parse(atob(currentUser.token.split('.')[1]));
        const expiresOn = new Date(token.exp * 1000);
        if (expiresOn < new Date(Date.now())) {
          this.setTokenExpiredState();
        } else {
          setTimeout(
            () => {
              this.setTokenExpiredState();
            },
            Math.abs(Math.floor(new Date().getTime() - token.exp * 1000))
          );
        }
      }
    }
  }

  public isUserAuthenticated(): boolean {
    if (localStorage.getItem('currentUser')) {
      const currentUser = localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser') || '{}')
        : null;
      if (Object.keys(currentUser).length > 0 && !currentUser.isTokenExpired) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkIfUserAuthenticated(): void {
    if (this.isUserAuthenticated()) {
      this.isUserLoggedIn.emit(true);
    } else {
      this.isUserLoggedIn.emit(false);
      this.tokenExpired.emit(true);
    }
  }

  public isTokenExpired(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.token) {
      const token = JSON.parse(atob(currentUser.token.split('.')[1]));
      const expiresOn = new Date(token.exp * 1000);
      if (expiresOn < new Date(Date.now())) {
        return true;
      }
    }
    return false;
  }

  public setTokenExpiredState(): void {
    localStorage.removeItem('userDetails');
    if (localStorage.getItem('currentUser')) {
      const currentUser = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      currentUser.token = '';
      currentUser.isTokenExpired = true;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
    this.router.navigate(['/login']);
    this.tokenExpired.emit(true);
  }

  public logout(): void {
    this.router.navigate(['/login']);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userDetails');
    this.isUserLoggedIn.emit(false);
  }
}
